import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";
import Swal from "sweetalert2";
import Bound from "./Bound";
import { set } from "react-hook-form";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import AdvisorStrategyDropdown from "./Components/AdvisorStrategyDropdown";

export default function Providers() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalStrategy, setOpenModalStrategy] = useState(false);

  const [inputs, setInputs] = useState({});
  const [boundData, setBoundData] = useState({});

  const [bounds, setBounds] = useState(["Bound 1"]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  function addBound() {
    const number = bounds.length + 1;
    setBounds([...bounds, "Bound " + number]);
  }

  const deleteBound = (value) => {
    setBounds((bounds) => {
      return bounds.filter((bound) => bound !== value);
    });
  };

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Strategy added");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // loading strategies
  const { data: strategies, isLoading } = useQuery({
    queryFn: () => sendGetRequest("strategies", {}),
    queryKey: ["strategies"],
  });

  const areYouSureBound = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBound(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        strategyDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Strategy deleted", background: "#de2016" });
  };

  // deleting strategy
  const strategyDelete = async (id) => {
    const res = await sendPostRequest("strategy/delete", {
      strategyID: id,
    });
    console.log(res);

    queryClient.invalidateQueries({ queryKey: ["strategies"] });
  };

  // adding strategy
  const strategyAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return strategyAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["strategies"] });
    },
  });

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return strategyDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["strategies"] });
      deletedNotyf();
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCloseModalStrategy() {
    setOpenModalStrategy(false);
  }

  if (!isLoading) {
    return (
      <>
        <Header />

        <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between w-[94%] ml-[3%] mt-[3%] pb-6">
                <h3 className="text-base font-semibold text-gray-900">
                  Strategies
                </h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0">
                  <button
                    type="button"
                    onClick={() => setOpenModal(true)}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Add Strategy
                  </button>
                </div>
              </div>

              {/* <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                <div className="flex items-center flex-1 space-x-4">
                  <h2 className="p-6 text-xl font-bold text-gray-900 dark:text-white">
                    Strategies
                  </h2>
                </div>
                <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                  <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                    <button
                      type="button"
                      onClick={() => setOpenModal(true)}
                      className="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-slate-800"
                    >
                      Add Strategy
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="overflow-x-auto p-6">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Active Companies
                      </th>
                      <th scope="col" className="px-4 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {strategies?.strategies.map((strategy) => (
                      <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                          {strategy.strategyName}
                        </td>

                        <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                          {strategy.activeCompanies}
                        </td>

                        <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <a
                            onClick={() => {
                              if (areYouSure(strategy.strategyID)) {
                                strategyDelete(strategy.strategyID);
                              }
                            }}
                            className="text-red-600 hover:text-red-900"
                          >
                            <Button color="failure" size="sm">
                              Delete
                            </Button>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-3">
              <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                Add Strategy
              </h2>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Name*
                  </label>
                  <input
                    type="input"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={inputs.name || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <AdvisorStrategyDropdown
                  label={"Team Leader"}
                  name={"teamLeader"}
                  handleChange={handleChange}
                />

                <div className="w-1/4 p-1">
                  <label
                    for="teamLeaderPercentage"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Team Leader %*
                  </label>
                  <input
                    type="input"
                    id="teamLeaderPercentage"
                    name="teamLeaderPercentage"
                    onChange={handleChange}
                    value={inputs.teamLeaderPercentage || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <AdvisorStrategyDropdown
                  label={"Team Leader 2"}
                  name={"teamLeader2"}
                  handleChange={handleChange}
                />
                <div className="w-1/4 p-1">
                  <label
                    for="teamLeader2Percentage"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Team Leader 2 %
                  </label>
                  <input
                    type="input"
                    id="teamLeader2Percentage"
                    name="teamLeader2Percentage"
                    onChange={handleChange}
                    value={inputs.teamLeader2Percentage || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="thirdParty"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    3rd Party
                  </label>
                  <input
                    type="input"
                    id="thirdParty"
                    name="thirdParty"
                    onChange={handleChange}
                    value={inputs.thirdParty || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="company"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Company
                  </label>
                  <input
                    type="input"
                    id="company"
                    name="company"
                    onChange={handleChange}
                    value={inputs.company || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="minimumFee"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Minimum Fee
                  </label>
                  <input
                    type="input"
                    id="minimumFee"
                    name="minimumFee"
                    onChange={handleChange}
                    value={inputs.minimumFee || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => addBound()}
                  className="h-5 rounded-full bg-green-600 p-1 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  <PlusIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </div>

              {bounds.map((item, key) => (
                <div key={key} className="py-1">
                  <Bound
                    item={item}
                    areYouSureBound={areYouSureBound}
                    setInputs={setInputs}
                    inputs={inputs}
                    boundData={boundData}
                    setBoundData={setBoundData}
                    id={key}
                  />
                </div>
              ))}

              <div className="flex justify-start mb-4">
                <button
                  type="button"
                  onClick={async () => {


                    if (!inputs.name) {
                      alert("Please enter a Strategy name");
                      return;
                    }

                    if (!inputs.teamLeader) {
                      alert("Please select a Team Leader");
                      return;
                    }

                    if (!inputs.teamLeaderPercentage) {
                      alert("Please enter a Team Leader %");
                      return;
                    }


                    try {
                      await doAddMutation({
                        path: "strategy/add",
                        body: {
                          strategyName: inputs.name,
                          teamLeader:
                            inputs.teamLeader > 0 ? inputs.teamLeader : 0,
                          teamLeader2:
                            inputs.teamLeader2 > 0 ? inputs.teamLeader2 : 0,
                          teamLeaderPercentage:
                            inputs.teamLeaderPercentage > 0
                              ? inputs.teamLeaderPercentage
                              : 0,
                          teamLeader2Percentage:
                            inputs.teamLeader2Percentage > 0
                              ? inputs.teamLeader2Percentage
                              : 0,
                          thirdParty:
                            inputs.thirdParty > 0 ? inputs.thirdParty : 0,
                          company: inputs.company > 0 ? inputs.company : 0,
                          boundData: JSON.stringify(boundData),
                          minimumFee: inputs.minimumFee,
                        },
                      });

                      setOpenModal(false);
                      setInputs({});

                      navigate(`/strategies`, {
                        replace: true,
                        state: { added: 1 },
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
                >
                  Add Strategy
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
