import { use, useState, useEffect } from "react";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../../Client";
import AdvisorDropdown from "./Components/AdvisorDropdown";
import IntroducerDropdown from "./Components/IntroducerDropdown";
import SecondAdvisorDropdown from "./Components/SecondAdvisorDropdown";
import ReactLoading from "react-loading";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Table, Modal } from "flowbite-react";
import dateFormat, { masks } from "dateformat";

export default function Headline(props) {
  const [fetchingTrove, setFetchingTrove] = useState(false);
  const [trove, setTrove] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(0);

  const queryClient = useQueryClient();

  function onCloseModal() {
    setOpenModal(false);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    props.setInputs((values) => ({ ...values, [name]: value }));
  };

  const { status: statusCompanies, data: dataCompanies } = useGetRequest(
    "companies",
    {}
  );

  const updateApprovedSubmitted = async (path, body) => {
    const res = await sendGetRequest(path, body);
    console.log(res);
    setFetchingTrove(false);
    setTrove(res);
    setOpenModal(true);
  };

  const { mutateAsync: doUpdateApprovedMutation } = useMutation({
    mutationFn: (data) => {
      return updateApprovedSubmitted(data.path, {
        // submittedID,
        ...data.body,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetch"] });
    },
  });

  const fetchNewBusiness = () => {
    doUpdateApprovedMutation({
      path: "trove/fetch",
      body: {
        // submittedID: submittedID,
        // checked: value,
      },
    });

    setSelected(0);
  };

  if (statusCompanies == "fetched") {
    return (
      <>
        <div className="flex flex-col p-2 bg-white  ">
          <div className="border-b border-gray-200 pb-5 flex flex-row justify-between">
            <h3 className="text-base font-semibold text-gray-900 mt-1 pb-2">
              Headline Information
            </h3>
            {!fetchingTrove ? (
              <button
                type="button"
                onClick={() => {
                  fetchNewBusiness();
                  setFetchingTrove(true);
                }}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Fetch from Trove
              </button>
            ) : (
              <ReactLoading
                type={"spin"}
                color="#5046e5"
                height={35}
                width={35}
              />
            )}
          </div>

          <div className="flex flex-row mt-4">
            <div className="w-1/5 p-2">
              <label
                for="company"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Regulated / Servicing Company*
              </label>
              <select
                id="company"
                name="company"
                onChange={() => {
                  props.setCompanyID(document.getElementById("company").value);
                }}
                required
                value={props.companyID}
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value={0}></option>
                {dataCompanies.companies.map((company) => (
                  <option value={company.companyID}>
                    {company.companyName}
                  </option>
                ))}
              </select>
            </div>

            <AdvisorDropdown
              label={"Ultimate Owner"}
              name={"ultimate_owner"}
              handleChange={handleChange}
              companyID={props.companyID}
            />

            <div className="w-1/5 p-2">
              <label
                for="start_date"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Start Date*
              </label>
              <input
                type="date"
                id="start_date"
                name="start_date"
                value={props.inputs.start_date || ""}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="product"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Main Product
              </label>
              <select
                id="product"
                name="product"
                value={props.inputs.product || ""}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                onChange={handleChange}
              >
                <option value=""></option>
                <option value="WRAP">WRAP</option>
                <option value="Insurance">Insurance</option>
                <option value="Investment">Investment</option>
                <option value="Pension">Pension</option>
              </select>
            </div>

            {trove?.results?.length === 0 || !trove ? (
              <div className="w-1/5 p-2">
                <label
                  for="product_provider"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Provider
                </label>
                <select
                  id="product_provider"
                  name="product_provider"
                  value={props.inputs.product_provider || ""}
                  required
                  className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  {props.inputs.product == "WRAP" ? (
                    <>
                      <option value="AJ Bell">AJ Bell</option>
                      <option value="Ardan International">
                        Ardan International
                      </option>
                      <option value="Ascentric">Ascentric</option>
                      <option value="Capital Platform">Capital Platform</option>
                    </>
                  ) : null}
                  {props.inputs.product == "Insurance" ? (
                    <>
                      <option value="Friends Life">Friends Life</option>
                      <option value="Friends Provident">
                        Friends Provident
                      </option>
                      <option value="Generali/Utmost WW">
                        Generali/Utmost WW
                      </option>
                      <option value="Utmost">Utmost</option>
                    </>
                  ) : null}
                  {props.inputs.product == "Investment" ? (
                    <>
                      <option value="AJ Bell">AJ Bell</option>
                      <option value="Ardan International">
                        Ardan International
                      </option>
                      <option value="Ascentric">Ascentric</option>
                      <option value="Brooks MacDonald">Brooks MacDonald</option>
                    </>
                  ) : null}
                  {props.inputs.product == "Pension" ? (
                    <>
                      <option value="Momentum">Momentum</option>
                      <option value="Morningstar">Morningstar</option>
                      <option value="Novia">Novia</option>
                      <option value="Novia Global">Novia Global</option>
                    </>
                  ) : null}
                </select>
              </div>
            ) : (
              <div className="w-1/5 p-2">
                <label
                  for="product_provider"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Provider
                </label>
                <select
                  id="product_provider"
                  name="product_provider"
                  value={props.inputs.product_provider || ""}
                  required
                  className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="AJ Bell">AJ Bell</option>
                  <option value="Ardan International">
                    Ardan International
                  </option>
                  <option value="Ascentric">Ascentric</option>
                  <option value="Capital Platform">Capital Platform</option>
                  <option value="Friends Life">Friends Life</option>
                  <option value="Friends Provident">Friends Provident</option>
                  <option value="Generali/Utmost WW">Generali/Utmost WW</option>
                  <option value="Utmost">Utmost</option>
                  <option value="Ascentric">Ascentric</option>
                  <option value="Brooks MacDonald">Brooks MacDonald</option>
                  <option value="Momentum">Momentum</option>
                  <option value="Morningstar">Morningstar</option>
                  <option value="Novia">Novia</option>
                  <option value="Novia Global">Novia Global</option>
                </select>
              </div>
            )}
          </div>

          <div className="flex flex-row">
            <div className="w-1/5 p-2">
              <label
                for="forename"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Client Forename
              </label>
              <input
                type="text"
                id="forename"
                name="forename"
                onChange={handleChange}
                value={props.inputs.forename || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="surname"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Client Surname
              </label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={props.inputs.surname || ""}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="client_ref"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Client Reference
              </label>
              <input
                type="text"
                id="client_ref"
                name="client_ref"
                value={props.inputs.client_ref || ""}
                onChange={handleChange}
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="bond_platform_no"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Policy No of Bond or Platform
              </label>
              <input
                type="text"
                id="bond_platform_no"
                name="bond_platform_no"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex flex-row mt-2">
            <div className="w-1/5 p-2">
              <label
                for="initial_total_value"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Initial Total Value*
              </label>
              <input
                type="text"
                id="initial_total_value"
                name="initial_total_value"
                onChange={handleChange}
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="currency"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Currency
              </label>
              <select
                id="currency"
                name="currency"
                defaultValue={"£"}
                onChange={() => {
                  props.setCurrency(document.getElementById("currency").value);
                }}
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value="1">£</option>
                <option value="2">$</option>
                <option value="3">€</option>
                <option value="4">CZK</option>
              </select>
            </div>

            {props.currency > 1 ? (
              <div className="w-1/5 p-2">
                <label
                  for="exchange_rate"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Exchange Rate
                </label>
                <input
                  type="text"
                  id="exchange_rate"
                  name="exchange_rate"
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            ) : null}
          </div>

          <div className="flex flex-row mt-2">
            <AdvisorDropdown
              label={"Advisor / Member Name"}
              name={"advisor"}
              handleChange={handleChange}
              companyID={props.companyID}
            />

            <div className="w-1/5 p-2">
              <label
                for="agency"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Agency
              </label>
              <select
                id="agency"
                name="agency"
                onChange={handleChange}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>
                <option value="AI">AI</option>
                <option value="AFP">AFP</option>
              </select>
            </div>
          </div>

          <div className="flex flex-row mt-2">
            <IntroducerDropdown
              label={"Introducer"}
              name={"introducer"}
              handleChange={handleChange}
              companyID={props.companyID}
            />

            <div className="w-1/5 p-2">
              <label
                for="introducer_split_from_advisor"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Introducer Split
              </label>
              <input
                type="text"
                id="introducer_split_from_advisor"
                name="introducer_split_from_advisor"
                onChange={handleChange}
                value={props.inputs.introducer_split_from_advisor || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-1/5 p-2">
              <label
                for="applied_to"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Split Fees Paid From
              </label>
              <select
                id="applied_to"
                name="applied_to"
                onChange={handleChange}
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>
                <option value="Whole Fee">Whole Fee</option>
                <option value="Advisor Fee Only">Advisor Fee Only</option>
              </select>
            </div>

            <div className="w-1/5 p-2">
              <label
                for="second_advisor_split_from_advisor"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                2nd Advisor Split From Advisor
              </label>
              <input
                type="text"
                id="second_advisor_split_from_advisor"
                name="second_advisor_split_from_advisor"
                onChange={handleChange}
                value={props.inputs.second_advisor_split_from_advisor || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <SecondAdvisorDropdown
              label={"2nd second_advisor"}
              name={"second_advisor"}
              companyID={props.companyID}
              handleChange={handleChange}
            />
          </div>
        </div>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="w-full text-center text-xl font-semibold text-gray-900 mb-6">
                    Trove Results
                  </div>

                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-1/4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Client Name
                        </th>
                        <th
                          scope="col"
                          className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Product Type
                        </th>
                        <th
                          scope="col"
                          className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Provider
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {trove?.results?.map((item, key) => (
                        <tr
                          className={
                            (item.id == selected ? "bg-green-100" : "") +
                            " cursor-pointer hover:bg-green-50"
                          }
                          onClick={() => {
                            setSelected(item.id);
                            props.setInputs((values) => ({
                              ...values,
                              forename: item.newName.firstname,
                              surname: item.newName.surname,
                              client_ref: item.bs_identifier,
                              product: item.mainProduct,
                              product_provider: item.providerName,
                              start_date: dateFormat(item.c_date, "yyyy-mm-dd"),
                            }));
                          }}
                          key={key}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {dateFormat(item.c_date, "mmmm dS, yyyy")}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.clientName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.mainProduct}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.providerName}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* {selected > 0 ? (
              <div className="mt-4 w-full pt-4 ml-[80%]">
                <a
                  className="w-[20%] py-2 inline-flex justify-center rounded-md bg-green-600 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 "
                  href="#"
                  onClick={onCloseModal}
                >
                  Confirm
                </a>
              </div>
            ) : null} */}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
