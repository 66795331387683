import {
  sendGetRequest,
  sendPostRequest,
  useGetRequest,
} from "../../../Client";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { Button, Table, Modal } from "flowbite-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import CountryDropdown from "../../../Components/CountryDropdown";
import { useNavigate } from "react-router-dom";
import PersonUpdate from "./update";

export default function People() {
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});

  const [personID, setPersonID] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const [advisor, setAdvisor] = useState(false);
  const [introducer, setIntroducer] = useState(false);
  const [leader, setLeader] = useState(false);

  const [compliance, setCompliance] = useState(false);
  const [finance, setFinance] = useState(false);
  const [strategies, setStrategies] = useState(false);

  const [payCompany, setPayCompany] = useState(false);

  const navigate = useNavigate();

  const { companyID } = useParams();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const queryClient = useQueryClient();

  // loading people
  const { data: people, isLoading } = useQuery({
    queryFn: () => sendGetRequest("people/" + companyID, {}),
    queryKey: ["people"],
  });

  console.log(people);

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Person deleted", background: "#de2016" });
  };

  // deleting person
  const personDelete = async (id) => {
    const res = await sendPostRequest("person/delete", {
      personID: id,
    });
    console.log(res);
  };

  const addedNotyf = () => {
    notyf.success("Person added");
  };

  // adding person
  const personAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return personAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["people"] });
    },
  });

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return personDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["people"] });
      deletedNotyf();
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCloseModalEdit() {
    setOpenModalEdit(false);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const countries = CountryDropdown();

  const { status: statusAdvisors, data: dataAdvisors } = useGetRequest(
    "people/dropdown/advisors/" + companyID,
    {}
  );

  const { status: statusLeaders, data: dataLeaders } = useGetRequest(
    "people/dropdown/leaders/" + companyID,
    {}
  );

  const { status: statusIntroducers, data: dataIntroducers } = useGetRequest(
    "people/dropdown/introducers/" + companyID,
    {}
  );

  const { status: statusContractTypes, data: dataContractTypes } =
    useGetRequest("people/dropdown/contractTypes/" + companyID, {});

  const { status: statusLinkedCompanies, data: dataLinkedCompanies } =
    useGetRequest("companies/linked/" + companyID, {});


  if (
    !isLoading &&
    statusAdvisors == "fetched" &&
    statusLeaders == "fetched" &&
    statusContractTypes == "fetched" &&
    statusIntroducers == "fetched" &&
    statusLinkedCompanies == "fetched"
  ) {
    return (
      <>
        <div className="flex flex-col p-2 border-2 border-slate-100 rounded-xl">
          <div className="flex flex-row justify-between items-center px-2 mt-2">
            <div>
              <h2 className="text-xl font-semibold leading-7 text-gray-900 px-2 mt-2">
                People
              </h2>
            </div>

            <button
              type="button"
              onClick={() => setOpenModal(true)}
              class="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-slate-800"
            >
              Add Person
            </button>
          </div>

          <div className="mt-8 flow-root ml-4 mr-4">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {people?.people?.length === 0 ? (
                  <>
                    <Table hoverable className="mb-4">
                      <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Type</Table.HeadCell>
                        <Table.HeadCell>Address</Table.HeadCell>
                        <Table.HeadCell></Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y">
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                          <Table.Cell className="w-1/4 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            No People found
                          </Table.Cell>

                          <Table.Cell className="w-1/4"></Table.Cell>
                          <Table.Cell className="w-2/4"></Table.Cell>
                          <Table.Cell className="w-1/4 flex mr-auto"></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </>
                ) : (
                  <Table hoverable className="mb-4">
                    <Table.Head>
                      <Table.HeadCell>Name</Table.HeadCell>
                      <Table.HeadCell>Type</Table.HeadCell>
                      <Table.HeadCell>Address</Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {people.people.map((person) => (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                          <Table.Cell className="w-1/4 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {person.surname}, {person.firstName}
                          </Table.Cell>

                          <Table.Cell className="w-1/4">
                            {person.advisor ? (
                              <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                Advisor
                              </span>
                            ) : null}
                            {person.introducer ? (
                              <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                Introducer
                              </span>
                            ) : null}
                            {person.leader ? (
                              <span className="inline-flex items-center rounded-full bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
                                Team Leader
                              </span>
                            ) : null}
                          </Table.Cell>
                          <Table.Cell className="w-2/4">
                            {person.address1}, {person.town}
                          </Table.Cell>

                          <Table.Cell className="w-1/8 flex">
                            <div className="mr-2">
                              <a
                                onClick={() => {
                                  setOpenModalEdit(true);
                                  setPersonID(person.personID);
                                }}
                                className="text-red-600 hover:text-red-900"
                              >
                                <Button color="warning" size="sm">
                                  View
                                </Button>
                              </a>
                            </div>
                            <div>
                              <a
                                onClick={() => {
                                  if (areYouSure(person.personID)) {
                                    personDelete(person.personID);
                                  }
                                }}
                                className="text-red-600 hover:text-red-900"
                              >
                                <Button color="failure" size="sm">
                                  Delete
                                </Button>
                              </a>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-3">
              <h2 class="text-lg font-bold text-gray-900 dark:text-white">
                Add Person
              </h2>

              <div className="flex flex-row mt-2">
                <div className="w-1/6 p-1">
                  <label
                    for="advisor"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Advisor
                  </label>
                  <input
                    type="checkbox"
                    id="advisor"
                    checked={advisor}
                    onChange={() => setAdvisor(!advisor)}
                    className="align-center h-4"
                  />
                </div>

                <div className="w-1/6 p-1">
                  <label
                    for="introducer"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Introducer
                  </label>
                  <input
                    type="checkbox"
                    id="introducer"
                    checked={introducer}
                    onChange={() => setIntroducer(!introducer)}
                    className="align-center h-4"
                  />
                </div>

                <div className="w-1/6 p-1">
                  <label
                    for="leader"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Team Leader
                  </label>
                  <input
                    type="checkbox"
                    id="leader"
                    checked={leader}
                    onChange={() => setLeader(!leader)}
                    className="align-center h-4"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="firstName"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First Name
                  </label>
                  <input
                    type="input"
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    value={inputs.firstName || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="surname"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Surname
                  </label>
                  <input
                    type="input"
                    id="surname"
                    name="surname"
                    onChange={handleChange}
                    value={inputs.surname || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                {advisor || leader ? (
                  <div className="w-1/4 p-1">
                    <label
                      for="tradingBrand"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Trading Brand
                    </label>
                    <input
                      type="input"
                      id="tradingBrand"
                      name="tradingBrand"
                      onChange={handleChange}
                      value={inputs.tradingBrand || ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                ) : null}

                <div className="w-1/4 p-1">
                  <label
                    for="contractType"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Contract Type
                  </label>
                  <select
                    id="contractType"
                    name="contractType"
                    onChange={handleChange}
                    value={inputs.contractType || ""}
                    required
                    className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {dataContractTypes.contractTypes.map((types) => (
                      <option value={types.companyContractID}>
                        {types.contractName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="id_number"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    ID Number
                  </label>
                  <input
                    type="input"
                    id="id_number"
                    name="id_number"
                    onChange={handleChange}
                    value={inputs.id_number || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>

                <div className="w-1/4 p-1">
                  <label
                    for="vatNumber"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    VAT Number
                  </label>
                  <input
                    type="input"
                    id="vatNumber"
                    name="vatNumber"
                    onChange={handleChange}
                    value={inputs.vatNumber || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>

                <div className="w-1/4 p-1">
                  <label
                    for="bankConnection"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Bank Connection
                  </label>
                  <input
                    type="input"
                    id="bankConnection"
                    name="bankConnection"
                    onChange={handleChange}
                    value={inputs.bankConnection || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>

                <div className="w-1/4 p-1">
                  <label
                    for="bankDetails"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Bank Details
                  </label>
                  <input
                    type="input"
                    id="bankDetails"
                    name="bankDetails"
                    onChange={handleChange}
                    value={inputs.bankDetails || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="address1"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Address Line 1
                  </label>
                  <input
                    type="input"
                    id="address1"
                    name="address1"
                    onChange={handleChange}
                    value={inputs.address1 || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="address2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Address Line 2
                  </label>
                  <input
                    type="input"
                    id="address2"
                    name="address2"
                    onChange={handleChange}
                    value={inputs.address2 || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="town"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Town
                  </label>
                  <input
                    type="input"
                    id="town"
                    name="town"
                    onChange={handleChange}
                    value={inputs.town || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="county"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    County
                  </label>
                  <input
                    type="input"
                    id="county"
                    name="county"
                    onChange={handleChange}
                    value={inputs.county || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="postcode"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Postcode
                  </label>
                  <input
                    type="input"
                    id="postcode"
                    name="postcode"
                    onChange={handleChange}
                    value={inputs.postcode || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="country"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    onChange={handleChange}
                    value={inputs.country || ""}
                    required
                    className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {countries.map((country) => (
                      <option value={country.code}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-row">
                {advisor ? (
                  <div className="w-1/4 p-1">
                    <label
                      for="sharedWithAdvisor"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      2nd Adviser % paid (override)
                    </label>
                    <input
                      type="input"
                      id="sharedWithAdvisor"
                      name="sharedWithAdvisor"
                      onChange={handleChange}
                      value={inputs.sharedWithAdvisor || ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                ) : null}
                {introducer ? (
                  <div className="w-1/4 p-1">
                    <label
                      for="paidAsIntroducer"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Introducer % paid (override)
                    </label>
                    <input
                      type="input"
                      id="paidAsIntroducer"
                      name="paidAsIntroducer"
                      onChange={handleChange}
                      value={inputs.paidAsIntroducer || ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                ) : null}
                {leader ? (
                  <div className="w-1/4 p-1">
                    <label
                      for="paidAsTeamLeader"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Team Leader % paid (override)
                    </label>
                    <input
                      type="input"
                      id="paidAsTeamLeader"
                      name="paidAsTeamLeader"
                      onChange={handleChange}
                      value={inputs.paidAsTeamLeader || ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                ) : null}
              </div>

              {introducer ? (
                <div className="flex flex-row">
                  <div className="w-1/4 p-1">
                    <label
                      for="associatedAdvisor"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Associated Advisor?
                    </label>
                    <select
                      id="associatedAdvisor"
                      name="associatedAdvisor"
                      onChange={handleChange}
                      value={inputs.associatedAdvisor || ""}
                      required
                      className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value=""></option>
                      {dataAdvisors?.people?.map((person) => (
                        <option value={person.personID}>
                          {person.surname}, {person.firstName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}

              {advisor || leader ? (
                <>
                  <div className="flex flex-row">
                    <div className="w-1/4 p-1">
                      <label
                        for="associatedTeamLeader"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Team Leader?
                      </label>
                      <select
                        id="associatedTeamLeader"
                        name="associatedTeamLeader"
                        onChange={handleChange}
                        value={inputs.associatedTeamLeader || ""}
                        required
                        className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value=""></option>
                        {dataLeaders?.people?.map((person) => (
                          <option value={person.personID}>
                            {person.surname}, {person.firstName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {inputs.associatedTeamLeader ? (
                      <div className="w-1/4 p-1">
                        <label
                          for="associatedTeamLeaderPercentage"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Team Leader Percentage
                        </label>
                        <input
                          type="input"
                          id="associatedTeamLeaderPercentage"
                          name="associatedTeamLeaderPercentage"
                          onChange={handleChange}
                          value={inputs.associatedTeamLeaderPercentage || ""}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                    ) : null}
                    <div className="w-1/4 p-1">
                      <label
                        for="portfolioInvestmentSplits"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Portfolio/Investment Splits?
                      </label>
                      <input
                        type="input"
                        id="portfolioInvestmentSplits"
                        name="portfolioInvestmentSplits"
                        onChange={handleChange}
                        value={inputs.portfolioInvestmentSplits || ""}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="w-1/4 p-1">
                      <label
                        for="earningsSplits"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Earnings Splits?
                      </label>
                      <input
                        type="input"
                        id="earningsSplits"
                        name="earningsSplits"
                        onChange={handleChange}
                        value={inputs.earningsSplits || ""}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="w-1/4 p-1">
                      <label
                        for="teamLeader2"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        2nd Team Leader?
                      </label>
                      <select
                        id="teamLeader2"
                        name="teamLeader2"
                        onChange={handleChange}
                        value={inputs.teamLeader2 || ""}
                        className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value=""></option>
                        {dataLeaders?.people?.map((person) => (
                          <option value={person.personID}>
                            {person.surname}, {person.firstName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-1/4 p-1">
                      <label
                        for="portfolioInvestmentSplits2"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Portfolio/Investment Splits?
                      </label>
                      <input
                        type="input"
                        id="portfolioInvestmentSplits2"
                        name="portfolioInvestmentSplits2"
                        onChange={handleChange}
                        value={inputs.portfolioInvestmentSplits2 || ""}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="w-1/4 p-1">
                      <label
                        for="earningsSplits2"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Earnings Splits?
                      </label>
                      <input
                        type="input"
                        id="earningsSplits2"
                        name="earningsSplits2"
                        onChange={handleChange}
                        value={inputs.earningsSplits2 || ""}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex flex-row mt-2 justify-start text-sm leading-6 text-gray-600 px-2 underline">
                    Areas of Management
                  </div>

                  <div className="flex flex-row mt-2">
                    <div className="w-1/5 p-1">
                      <label
                        for="compliance"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Compliance
                      </label>
                      <input
                        type="checkbox"
                        id="compliance"
                        checked={compliance}
                        onChange={() => setCompliance(!compliance)}
                        className="align-center h-4"
                      />
                    </div>

                    <div className="w-1/5 p-1">
                      <label
                        for="finance"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Finance
                      </label>
                      <input
                        type="checkbox"
                        id="finance"
                        checked={finance}
                        onChange={() => setFinance(!finance)}
                        className="align-center h-4"
                      />
                    </div>

                    <div className="w-1/5 p-1">
                      <label
                        for="strategies"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Strategies
                      </label>
                      <input
                        type="checkbox"
                        id="strategies"
                        checked={strategies}
                        onChange={() => setStrategies(!strategies)}
                        className="align-center h-4"
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <div className="flex flex-row mt-2">
                <div className="w-1/4 p-1">
                  <label
                    for="linkedCompany"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Linked Company
                  </label>
                  <select
                    id="linkedCompany"
                    name="linkedCompany"
                    onChange={handleChange}
                    value={inputs.linkedCompany || ""}
                    required
                    className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {dataLinkedCompanies.companies.map((companies) => (
                      <option value={companies.companyID}>
                        {companies.companyName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/5 p-1 ml-12">
                  <label
                    for="payCompany"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Pay company instead of Person?
                  </label>
                  <input
                    type="checkbox"
                    id="payCompany"
                    checked={payCompany}
                    onChange={() => setPayCompany(!payCompany)}
                    className="align-center h-4"
                  />
                </div>
              </div>

              <button
                type="button"
                onClick={async () => {
                  try {
                    await doAddMutation({
                      path: "people/add",
                      body: {
                        companyID: companyID,
                        advisor: advisor ? 1 : 0,
                        introducer: introducer ? 1 : 0,
                        leader: leader ? 1 : 0,
                        firstName: inputs.firstName,
                        surname: inputs.surname,
                        ID: inputs.id_number,
                        vatNumber: inputs.vatNumber,
                        bankConnection: inputs.bankConnection,
                        bankDetails: inputs.bankDetails,
                        address1: inputs.address1,
                        address2: inputs.address2,
                        town: inputs.town,
                        county: inputs.county,
                        postcode: inputs.postcode,
                        country: inputs.country,
                        associatedTeamLeader: inputs.associatedTeamLeader,
                        associatedTeamLeaderPercentage:
                          inputs.associatedTeamLeaderPercentage,
                        portfolioInvestmentSplits:
                          inputs.portfolioInvestmentSplits,
                        earningsSplits: inputs.earningsSplits,
                        associatedTeamLeader2: inputs.associatedTeamLeader2,
                        portfolioInvestmentSplits2:
                          inputs.portfolioInvestmentSplits2,
                        earningsSplits2: inputs.earningsSplits2,
                        compliance: compliance ? 1 : 0,
                        finance: finance ? 1 : 0,
                        strategies: strategies ? 1 : 0,
                        tradingBrand: inputs.tradingBrand,
                        associatedAdvisor: inputs.associatedAdvisor,
                        associatedAdvisor2: inputs.associatedAdvisor2,
                        contractType: inputs.contractType,
                        linkedCompany: inputs.linkedCompany,
                        payCompany: payCompany ? 1 : 0,
                      },
                    });

                    setOpenModal(false);
                    setInputs({});

                    setAdvisor(false);
                    setIntroducer(false);
                    setLeader(false);
                    setCompliance(false);
                    setFinance(false);

                    navigate(`/companies/edit/${companyID}`, {
                      replace: true,
                      state: { added: 1 },
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
                class="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
              >
                Add Person
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {personID && (
          <PersonUpdate
            openModalEdit={openModalEdit}
            setOpenModalEdit={setOpenModalEdit}
            onCloseModalEdit={onCloseModalEdit}
            personID={personID}
            companyID={companyID}
            // updatedNotyf={updatedNotyf}
            // customerID={customerID}
            // queryClient={queryClient}
            // divisionID={divisionID}
          />
        )}
      </>
    );
  }
}
