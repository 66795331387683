import { Button, Table, Modal } from "flowbite-react";
import { useGetRequest } from "../../../Client";
import { React, useState, useEffect } from "react";

export default function PersonUpdate(props) {
  const [firstName, setFirstName] = useState();
  const [surname, setSurname] = useState();
  const [contract, setContract] = useState();
  const [portfolioInvestmentSplits, setPortfolioInvestmentSplits] = useState();
  const [earningsSplits, setEarningsSplits] = useState();
  const [associatedTeamLeaderPercentage, setAssociatedTeamLeaderPercentage] =
    useState();

  const { status: statusContractTypes, data: dataContractTypes } =
    useGetRequest("people/dropdown/contractTypes/" + props.companyID, {});

  const { status: statusPerson, data: dataPerson } = useGetRequest(
    "people/details/" + props.personID,
    {}
  );

  useEffect(() => {
    setFirstName(dataPerson?.person?.firstName);
    setSurname(dataPerson?.person?.surname);
    setContract(dataPerson?.person?.contractName);
    setPortfolioInvestmentSplits(dataPerson?.person?.portfolioInvestmentSplits ? dataPerson?.person?.portfolioInvestmentSplits : "0");
    setEarningsSplits(dataPerson?.person?.earningsSplits ? dataPerson?.person?.earningsSplits : "0");
    setAssociatedTeamLeaderPercentage(
      dataPerson?.person?.associatedTeamLeaderPercentage ? dataPerson?.person?.associatedTeamLeaderPercentage : "0"
    );
  }, [dataPerson]);


  return (
    <Modal
      show={props.openModalEdit}
      size="5xl"
      onClose={props.onCloseModalEdit}
      popup
    >
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-3">
          <h2 class="text-lg font-bold text-gray-900 dark:text-white">
            Edit Person
          </h2>

          <div className="flex flex-row mt-2">
            {/* <div className="w-1/6 p-1">
              <label
                for="advisor"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Advisor
              </label>
              <input
                type="checkbox"
                id="advisor"
                checked={advisor}
                onChange={() => setAdvisor(!advisor)}
                className="align-center h-4"
              />
            </div> */}

            {/* <div className="w-1/6 p-1">
              <label
                for="introducer"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Introducer
              </label>
              <input
                type="checkbox"
                id="introducer"
                checked={introducer}
                onChange={() => setIntroducer(!introducer)}
                className="align-center h-4"
              />
            </div> */}

            {/* <div className="w-1/6 p-1">
              <label
                for="leader"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Team Leader
              </label>
              <input
                type="checkbox"
                id="leader"
                checked={leader}
                onChange={() => setLeader(!leader)}
                className="align-center h-4"
              />
            </div> */}
          </div>

          <div className="flex flex-row">
            <div className="w-1/4 p-1">
              <label
                for="firstName"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                First Name
              </label>
              <input
                type="input"
                id="firstName"
                name="firstName"
                value={firstName ? firstName : ""}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="w-1/4 p-1">
              <label
                for="surname"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Surname
              </label>
              <input
                type="input"
                id="surname"
                name="surname"
                value={surname ? surname : ""}
                onChange={(e) => {
                  setSurname(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            {/* <div className="w-1/4 p-1">
                <label
                  for="tradingBrand"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Trading Brand
                </label>
                <input
                  type="input"
                  id="tradingBrand"
                  name="tradingBrand"
                  onChange={handleChange}
                  value={inputs.tradingBrand || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div> */}

            <div className="w-1/4 p-1">
              <label
                for="contract"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contract
              </label>
              <input
                type="input"
                id="contract"
                name="contract"
                value={contract ? contract : ""}
                onChange={(e) => {
                  setContract(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="flex flex-row">
            {/* <div className="w-1/4 p-1">
              <label
                for="id_number"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                ID Number
              </label>
              <input
                type="input"
                id="id_number"
                name="id_number"
                onChange={handleChange}
                value={inputs.id_number || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}

            {/* <div className="w-1/4 p-1">
              <label
                for="vatNumber"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                VAT Number
              </label>
              <input
                type="input"
                id="vatNumber"
                name="vatNumber"
                onChange={handleChange}
                value={inputs.vatNumber || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}

            {/* <div className="w-1/4 p-1">
              <label
                for="bankConnection"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Bank Connection
              </label>
              <input
                type="input"
                id="bankConnection"
                name="bankConnection"
                onChange={handleChange}
                value={inputs.bankConnection || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}

            {/* <div className="w-1/4 p-1">
              <label
                for="bankDetails"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Bank Details
              </label>
              <input
                type="input"
                id="bankDetails"
                name="bankDetails"
                onChange={handleChange}
                value={inputs.bankDetails || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}
          </div>

          <div className="flex flex-row">
            {/* <div className="w-1/4 p-1">
              <label
                for="address1"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Address Line 1
              </label>
              <input
                type="input"
                id="address1"
                name="address1"
                onChange={handleChange}
                value={inputs.address1 || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}
            {/* <div className="w-1/4 p-1">
              <label
                for="address2"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Address Line 2
              </label>
              <input
                type="input"
                id="address2"
                name="address2"
                onChange={handleChange}
                value={inputs.address2 || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}
            {/* <div className="w-1/4 p-1">
              <label
                for="town"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Town
              </label>
              <input
                type="input"
                id="town"
                name="town"
                onChange={handleChange}
                value={inputs.town || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}
          </div>

          <div className="flex flex-row">
            {/* <div className="w-1/4 p-1">
              <label
                for="county"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                County
              </label>
              <input
                type="input"
                id="county"
                name="county"
                onChange={handleChange}
                value={inputs.county || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}
            {/* <div className="w-1/4 p-1">
              <label
                for="postcode"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Postcode
              </label>
              <input
                type="input"
                id="postcode"
                name="postcode"
                onChange={handleChange}
                value={inputs.postcode || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div> */}
            {/* <div className="w-1/4 p-1">
              <label
                for="country"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Country
              </label>
              <select
                id="country"
                name="country"
                onChange={handleChange}
                value={inputs.country || ""}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>
                {countries.map((country) => (
                  <option value={country.code}>{country.name}</option>
                ))}
              </select>
            </div> */}
          </div>

          <div className="flex flex-row">
            {/* <div className="w-1/4 p-1">
                <label
                  for="sharedWithAdvisor"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  2nd Adviser % paid (override)
                </label>
                <input
                  type="input"
                  id="sharedWithAdvisor"
                  name="sharedWithAdvisor"
                  onChange={handleChange}
                  value={inputs.sharedWithAdvisor || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div> */}
            {/* <div className="w-1/4 p-1">
                <label
                  for="paidAsIntroducer"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Introducer % paid (override)
                </label>
                <input
                  type="input"
                  id="paidAsIntroducer"
                  name="paidAsIntroducer"
                  onChange={handleChange}
                  value={inputs.paidAsIntroducer || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div> */}
            {/* <div className="w-1/4 p-1">
                <label
                  for="paidAsTeamLeader"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Team Leader % paid (override)
                </label>
                <input
                  type="input"
                  id="paidAsTeamLeader"
                  name="paidAsTeamLeader"
                  onChange={handleChange}
                  value={inputs.paidAsTeamLeader || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div> */}
          </div>

          <div className="flex flex-row">
            {/* <div className="w-1/4 p-1">
                <label
                  for="associatedAdvisor"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Associated Advisor?
                </label>
                <select
                  id="associatedAdvisor"
                  name="associatedAdvisor"
                  onChange={handleChange}
                  value={inputs.associatedAdvisor || ""}
                  required
                  className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value=""></option>
                  {dataAdvisors?.people?.map((person) => (
                    <option value={person.personID}>
                      {person.surname}, {person.firstName}
                    </option>
                  ))}
                </select>
              </div> */}
          </div>

          <>
            <div className="flex flex-row">
              {/* <div className="w-1/4 p-1">
                  <label
                    for="associatedTeamLeader"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Team Leader?
                  </label>
                  <select
                    id="associatedTeamLeader"
                    name="associatedTeamLeader"
                    onChange={handleChange}
                    value={inputs.associatedTeamLeader || ""}
                    required
                    className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {dataLeaders?.people?.map((person) => (
                      <option value={person.personID}>
                        {person.surname}, {person.firstName}
                      </option>
                    ))}
                  </select>
                </div> */}
              <div className="w-1/4 p-1">
                <label
                  for="associatedTeamLeaderPercentage"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Team Leader Percentage
                </label>
                <input
                  type="input"
                  id="associatedTeamLeaderPercentage"
                  name="associatedTeamLeaderPercentage"
                  value={
                    associatedTeamLeaderPercentage
                      ? associatedTeamLeaderPercentage
                      : ""
                  }
                  onChange={(e) => {
                    setAssociatedTeamLeaderPercentage(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-1">
                <label
                  for="portfolioInvestmentSplits"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Portfolio/Investment Splits?
                </label>
                <input
                  type="input"
                  id="portfolioInvestmentSplits"
                  name="portfolioInvestmentSplits"
                  value={
                    portfolioInvestmentSplits ? portfolioInvestmentSplits : ""
                  }
                  onChange={(e) => {
                    setPortfolioInvestmentSplits(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="w-1/4 p-1">
                <label
                  for="earningsSplits"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Earnings Splits?
                </label>
                <input
                  type="input"
                  id="earningsSplits"
                  name="earningsSplits"
                  value={earningsSplits ? earningsSplits : ""}
                  onChange={(e) => {
                    setEarningsSplits(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>

            {/* <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="teamLeader2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    2nd Team Leader?
                  </label>
                  <select
                    id="teamLeader2"
                    name="teamLeader2"
                    onChange={handleChange}
                    value={inputs.teamLeader2 || ""}
                    className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {dataLeaders?.people?.map((person) => (
                      <option value={person.personID}>
                        {person.surname}, {person.firstName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="portfolioInvestmentSplits2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Portfolio/Investment Splits?
                  </label>
                  <input
                    type="input"
                    id="portfolioInvestmentSplits2"
                    name="portfolioInvestmentSplits2"
                    onChange={handleChange}
                    value={inputs.portfolioInvestmentSplits2 || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="earningsSplits2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Earnings Splits?
                  </label>
                  <input
                    type="input"
                    id="earningsSplits2"
                    name="earningsSplits2"
                    onChange={handleChange}
                    value={inputs.earningsSplits2 || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div> */}

            {/* <div className="mt-5 flex flex-row mt-2 justify-start text-sm leading-6 text-gray-600 px-2 underline">
              Areas of Management
            </div> */}

            {/* <div className="flex flex-row mt-2">
                <div className="w-1/5 p-1">
                  <label
                    for="compliance"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Compliance
                  </label>
                  <input
                    type="checkbox"
                    id="compliance"
                    checked={compliance}
                    onChange={() => setCompliance(!compliance)}
                    className="align-center h-4"
                  />
                </div>

                <div className="w-1/5 p-1">
                  <label
                    for="finance"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Finance
                  </label>
                  <input
                    type="checkbox"
                    id="finance"
                    checked={finance}
                    onChange={() => setFinance(!finance)}
                    className="align-center h-4"
                  />
                </div>

                <div className="w-1/5 p-1">
                  <label
                    for="strategies"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Strategies
                  </label>
                  <input
                    type="checkbox"
                    id="strategies"
                    checked={strategies}
                    onChange={() => setStrategies(!strategies)}
                    className="align-center h-4"
                  />
                </div>
              </div> */}
          </>

          <div className="flex flex-row mt-2">
            {/* <div className="w-1/4 p-1">
              <label
                for="linkedCompany"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Linked Company
              </label>
              <select
                id="linkedCompany"
                name="linkedCompany"
                onChange={handleChange}
                value={inputs.linkedCompany || ""}
                required
                className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value=""></option>
                {dataLinkedCompanies.companies.map((companies) => (
                  <option value={companies.companyID}>
                    {companies.companyName}
                  </option>
                ))}
              </select>
            </div> */}

            {/* <div className="w-1/5 p-1 ml-12">
              <label
                for="payCompany"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Pay company instead of Person?
              </label>
              <input
                type="checkbox"
                id="payCompany"
                checked={payCompany}
                onChange={() => setPayCompany(!payCompany)}
                className="align-center h-4"
              />
            </div> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
