import { useState, Fragment, React, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { Dialog, Transition } from "@headlessui/react";
import { MultiSelect } from "react-multi-select-component";
import dateFormat, { masks } from "dateformat";

export default function SubmittedBusiness() {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const [ongoingInitial, setOngoingInitial] = useState("Ongoing");

  const [searchProvider, setSearchProvider] = useState(0);
  const [searchCompany, setSearchCompany] = useState(0);
  const [searchAdvisor, setSearchAdvisor] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const [searchCurrency, setSearchCurrency] = useState("");

  const [fetchedData, setFetchedData] = useState([]);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currency, setCurrency] = useState(false);
  const [conversion, setConversion] = useState(0);

  const [showFiltering, setShowFiltering] = useState(false);

  const [inputs, setInputs] = useState({});

  const [submittedID, setSubmittedID] = useState();
  const [investedValue, setInvestedValue] = useState();
  const [advisorFee, setAdvisorFee] = useState();
  const [totalFee, setTotalFee] = useState();
  const [companyPercentage, setCompanyPercentage] = useState();
  const [companyFee, setCompanyFee] = useState();
  const [advisorNetFeePercentage, setAdvisorNetFeePercentage] = useState();
  const [advisorNetFee, setAdvisorNetFee] = useState();

  const [viewRecordID, setViewRecordID] = useState();
  const [viewStartDate, setViewStartDate] = useState();
  const [viewCompany, setViewCompany] = useState();
  const [viewClientName, setViewClientName] = useState();
  const [viewClientRef, setViewClientRef] = useState();
  const [viewAdvisor, setViewAdvisor] = useState();
  const [viewProduct, setViewProduct] = useState();
  const [viewProvider, setViewProvider] = useState();
  const [viewOwner, setViewOwner] = useState();
  const [viewInvestedValue, setViewInvestedValue] = useState();
  const [viewAdvisorFee, setViewAdvisorFee] = useState();
  const [viewTotalFee, setViewTotalFee] = useState();
  const [viewCompanyPercentage, setViewCompanyPercentage] = useState();
  const [viewCompanyFee, setViewCompanyFee] = useState();
  const [viewAdvisorNetFeePercentage, setViewAdvisorNetFeePercentage] =
    useState();
  const [viewAdvisorNetFeeAmount, setViewAdvisorNetFeeAmount] = useState();

  // loading submitted
  const { data: submitted, isLoading } = useQuery({
    queryFn: () => sendGetRequest("submitted", {}),
    queryKey: ["submitted"],
  });

  useEffect(() => {
    setFetchedData(submitted);
    setCurrency(submitted?.submitted[0]?.currency);
  }, [submitted]);

  const { status: statusCompanies, data: dataCompanies } = useGetRequest(
    "companies",
    {}
  );

  const { status: statusView, data: dataView } = useGetRequest(
    "submitted/view",
    {}
  );

  useEffect(() => {
    if (statusView === "fetched") {
      setViewRecordID(dataView?.view[0]?.recordID);
      setViewStartDate(dataView?.view[0]?.startDate);
      setViewCompany(dataView?.view[0]?.company);
      setViewClientName(dataView?.view[0]?.clientName);
      setViewClientRef(dataView?.view[0]?.clientRef);
      setViewAdvisor(dataView?.view[0]?.advisor);
      setViewProduct(dataView?.view[0]?.product);
      setViewProvider(dataView?.view[0]?.provider);
      setViewOwner(dataView?.view[0]?.owner);
      setViewInvestedValue(dataView?.view[0]?.investedValue);
      setViewAdvisorFee(dataView?.view[0]?.advisorFee);
      setViewTotalFee(dataView?.view[0]?.totalFee);
      setViewCompanyPercentage(dataView?.view[0]?.companyPercentage);
      setViewCompanyFee(dataView?.view[0]?.companyFee);
      setViewAdvisorNetFeePercentage(
        dataView?.view[0]?.advisorNetFeePercentage
      );
      setViewAdvisorNetFeeAmount(dataView?.view[0]?.advisorNetFeeAmount);
    }
  }, [dataView]);

  function onCloseModal() {
    setOpenModal(false);
  }

  function getCurrencySymbol(currency) {
    if (currency == 1) {
      return "£";
    } else if (currency == 2) {
      return "$";
    } else if (currency == 3) {
      return "€";
    } else if (currency == 4) {
      return "CZK";
    } else {
      return "£";
    }
  }

  const fetchPosts = async () => {
    setLoading(true);

    const requestBody = {
      // company: Number(searchCompany),
      company: selectedFormatted,
      fromDate: searchFromDate,
      toDate: searchToDate,
      // provider: searchProvider,
      provider: selectedProviderFormatted,
      client: searchClient,
      advisor: searchAdvisor,
      currency: searchCurrency,
    };

    const res = await sendPostRequest("submitted/search", requestBody);

    setFetchedData(res);

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearched(true);
    fetchPosts();
  };

  const sumInvestedTotal = () => {
    let total = 0;

    fetchedData?.submitted.forEach((a) => {
      total += Number(a.investedValue);
    });

    return (Math.round(total * conversion * 100) / 100).toFixed(2);
  };

  const sumFeeTotal = () => {
    let total = 0;

    fetchedData?.submitted.forEach((a) => {
      total += Number(a.totalClientFeePaidFromProduct);
    });

    return (Math.round(total * conversion * 100) / 100).toFixed(2);
  };

  const sumCompanyFeeTotal = () => {
    let total = 0;

    fetchedData?.submitted.forEach((a) => {
      if (conversion == 0) {
        total += Number(a.companyNetFeeAmount);
      } else {
        total += convert(a.companyNetFeeAmount, a.currency, conversion);
      }
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  const sumAdvisorFeeTotal = () => {
    let total = 0;

    fetchedData?.submitted.forEach((a) => {
      if (conversion == 0) {
        total += Number(a.advisorNetFeePayableAmount);
      } else {
        total += convert(a.advisorNetFeePayableAmount, a.currency, conversion);
      }
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  function numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  const submittedDelete = async (id) => {
    const res = await sendPostRequest("submitted/delete", {
      submittedID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete", id);
        submittedDelete(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // updating

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const updatedNotyf = () => {
    notyf.success("Record updated");
  };

  const updateSubmitted = async (path, body) => {
    const res = await sendPostRequest(path, body);
    updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateSubmitted(data.path, { submittedID, ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submitted"] });
    },
  });

  const update = () => {
    doUpdateMutation({
      path: "submitted/update",
      body: {
        submittedID: submittedID,
        investedValue: investedValue,
        advisorFee: advisorFee,
        totalFee: totalFee,
        companyPercentage: companyPercentage,
        companyFee: companyFee,
        advisorNetFeePercentage: advisorNetFeePercentage,
        advisorNetFee: advisorNetFee,
      },
    });
    setOpenModal(false);
  };

  const updatedViewNotyf = () => {
    notyf.success("View updated");
  };

  const updateViewSubmitted = async (path, body) => {
    const res = await sendPostRequest(path, body);
    updatedViewNotyf();
  };

  const { mutateAsync: doUpdateViewMutation } = useMutation({
    mutationFn: (data) => {
      return updateViewSubmitted(data.path, { submittedID, ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submitted"] });
    },
  });

  const updateView = () => {
    doUpdateViewMutation({
      path: "submitted/view/update",
      body: {
        viewRecordID: viewRecordID ? 1 : 0,
        viewStartDate: viewStartDate ? 1 : 0,
        viewCompany: viewCompany ? 1 : 0,
        viewClientName: viewClientName ? 1 : 0,
        viewClientRef: viewClientRef ? 1 : 0,
        viewAdvisor: viewAdvisor ? 1 : 0,
        viewProduct: viewProduct ? 1 : 0,
        viewProvider: viewProvider ? 1 : 0,
        viewOwner: viewOwner ? 1 : 0,
        viewInvestedValue: viewInvestedValue ? 1 : 0,
        viewAdvisorFee: viewAdvisorFee ? 1 : 0,
        viewTotalFee: viewTotalFee ? 1 : 0,
        viewCompanyPercentage: viewCompanyPercentage ? 1 : 0,
        viewCompanyFee: viewCompanyFee ? 1 : 0,
        viewAdvisorNetFeePercentage: viewAdvisorNetFeePercentage ? 1 : 0,
        viewAdvisorNetFeeAmount: viewAdvisorNetFeeAmount ? 1 : 0,
      },
    });
  };

  const [selected, setSelected] = useState([]);
  const [selectedFormatted, setSelectedFormatted] = useState([]);

  useEffect(() => {
    if (selected.length > 0) {
      setSelectedFormatted(selected.map((item) => item.value));
    }
  }, [selected]);

  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedProviderFormatted, setSelectedProviderFormatted] = useState(
    []
  );

  useEffect(() => {
    if (selectedProvider.length > 0) {
      setSelectedProviderFormatted(selectedProvider.map((item) => item.value));
    }
  }, [selectedProvider]);

  function convert(amount, fromCurrency, toCurrency) {
    console.log(amount, fromCurrency, toCurrency);

    // 1 gbp
    // 2 usd
    // 3 eur
    // 4 czk

    // let gbp = 1;
    // let usd = 1.27;
    // let eur = 1.21;
    // let czk = 30.38;

    if (fromCurrency == 1) {
      fromCurrency = 1;
    }
    if (fromCurrency == 2) {
      fromCurrency = 1.27;
    }
    if (fromCurrency == 3) {
      fromCurrency = 1.21;
    }
    if (fromCurrency == 4) {
      fromCurrency = 30.38;
    }

    if (toCurrency == 1) {
      toCurrency = 1;
    }
    if (toCurrency == 2) {
      toCurrency = 1.27;
    }
    if (toCurrency == 3) {
      toCurrency = 1.21;
    }
    if (toCurrency == 4) {
      toCurrency = 30.38;
    }

    return (toCurrency / fromCurrency) * amount;
  }

  if (!isLoading && statusCompanies === "fetched") {
    return (
      <>
        <Header />

        <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-20">
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              {/* <div className="border-b border-gray-200 w-[94%] ml-[3%] mt-[3%] pb-6">
                <h3 className="text-base font-semibold text-gray-900">
                  Submitted Business{" "}
                </h3>
              </div> */}

              <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between w-[94%] ml-[3%] mt-[3%] pb-6">
                <h3 className="text-base font-semibold text-gray-900">
                  Submitted Business
                </h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0">
                  <button
                    type="button"
                    onClick={() => setShowFiltering(!showFiltering)}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {showFiltering ? "Hide Filtering" : "Show Filtering"}
                  </button>
                </div>
              </div>

              {showFiltering ? (
                <form onSubmit={handleSubmit}>
                  <div className="px-2 py-2 mt-5">
                    <div className="w-full flex flex-row mt-4 justify-center align-end">
                      <div className="w-1/6">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="currency"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Currency
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            onChange={(e) => setSearchCurrency(e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option></option>
                            <option value={1}>£</option>
                            <option value={2}>$</option>
                            <option value={3}>€</option>
                            <option value={4}>CZK</option>
                          </select>
                        </div>
                      </div>

                      <div className="w-1/6">
                        <label
                          htmlFor="provider"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Provider{" "}
                        </label>
                        <div className="mt-2">
                          <MultiSelect
                            options={[
                              { label: "AJ Bell", value: "AJ Bell" },
                              {
                                label: "Ardan International",
                                value: "Ardan International",
                              },
                              { label: "Ascentric", value: "Ascentric" },

                              {
                                label: "Brooks MacDonald",
                                value: "Brooks MacDonald",
                              },
                              {
                                label: "Capital Platform",
                                value: "Capital Platform",
                              },
                              { label: "Friends Life", value: "Friends Life" },

                              {
                                label: "Friends Provident",
                                value: "Friends Provident",
                              },

                              {
                                label: "Generali/Utmost WW",
                                value: "Generali/Utmost WW",
                              },
                              { label: "Momentum", value: "Momentum" },
                              { label: "Morningstar", value: "Morningstar" },
                              { label: "Novia", value: "Novia" },
                              { label: "Novia Global", value: "Novia Global" },
                              { label: "Utmost", value: "Utmost" },
                            ]}
                            value={selectedProvider}
                            onChange={(selectedProvider) => {
                              // selectedFunction(selected);
                              setSelectedProvider(selectedProvider);
                            }}
                            labelledBy="Select"
                          />
                        </div>
                      </div>

                      <div className="w-1/6">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Company{" "}
                        </label>
                        <div className="mt-2 ml-2">
                          <MultiSelect
                            options={dataCompanies.companies.map(
                              (companies) => ({
                                label: companies.companyName,
                                value: companies.companyID,
                              })
                            )}
                            value={selected}
                            onChange={(selected) => {
                              // selectedFunction(selected);
                              setSelected(selected);
                            }}
                            labelledBy="Select"
                          />
                        </div>
                      </div>

                      <div className="w-1/6">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="client"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Client Name / Reference
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="client"
                              onChange={(e) => setSearchClient(e.target.value)}
                              name="client"
                              className=" border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-1/8">
                        <div className="mb-5 mx-2">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="fromDate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              From Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                onChange={(e) =>
                                  setSearchFromDate(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/8">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="toDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            To Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="toDate"
                              id="toDate"
                              onChange={(e) => setSearchToDate(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="w-20 ml-8 mt-8 h-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {loading ? (
                <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                  <ReactLoading type={"spin"} color="#5046e5" />
                </div>
              ) : (
                <div className="overflow-x-auto p-6">
                  <div className="flex flex-row mb-2">
                    <div
                      onClick={() => setOngoingInitial("Ongoing")}
                      className="bg-slate-100 p-4 hover:bg-slate-200 hover:cursor-pointer rounded-md w-24 text-center"
                    >
                      Ongoing
                    </div>
                    <div
                      onClick={() => setOngoingInitial("Initial")}
                      className="ml-2 bg-slate-100 p-4 hover:bg-slate-200 hover:cursor-pointer rounded-md w-24 text-center"
                    >
                      Initial
                    </div>
                    <div
                      onClick={() => setOpen(true)}
                      className="ml-8 bg-green-100 p-4 hover:bg-green-200 hover:cursor-pointer rounded-md w-30 text-center"
                    >
                      Manage View
                    </div>
                  </div>
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        {viewRecordID ? (
                          <th scope="col" className="px-4 py-3">
                            Record ID
                          </th>
                        ) : null}

                        {viewStartDate ? (
                          <th scope="col" className="px-4 py-3">
                            Start Date
                          </th>
                        ) : null}

                        {viewCompany ? (
                          <th scope="col" className="px-4 py-3">
                            Company
                          </th>
                        ) : null}

                        {viewClientName ? (
                          <th scope="col" className="px-4 py-3">
                            Client
                          </th>
                        ) : null}

                        {viewClientRef ? (
                          <th scope="col" className="px-4 py-3">
                            Client Ref
                          </th>
                        ) : null}

                        {viewAdvisor ? (
                          <th scope="col" className="px-4 py-3">
                            Advisor
                          </th>
                        ) : null}

                        {viewProduct ? (
                          <th scope="col" className="px-4 py-3">
                            Product
                          </th>
                        ) : null}

                        {viewProvider ? (
                          <th scope="col" className="px-4 py-3">
                            Provider
                          </th>
                        ) : null}

                        {viewOwner ? (
                          <th scope="col" className="px-4 py-3">
                            Owner
                          </th>
                        ) : null}

                        {viewOwner ? (
                          <th scope="col" className="px-4 py-3">
                            Finance Ref
                          </th>
                        ) : null}

                        {viewInvestedValue ? (
                          <th scope="col" className="px-4 py-3">
                            Invested Value
                          </th>
                        ) : null}

                        {viewAdvisorFee ? (
                          <th scope="col" className="px-4 py-3">
                            Advisor Fee
                          </th>
                        ) : null}

                        {viewTotalFee ? (
                          <th scope="col" className="px-4 py-3">
                            Total Fee
                          </th>
                        ) : null}

                        {viewCompanyPercentage ? (
                          <th scope="col" className="px-4 py-3">
                            Company %
                          </th>
                        ) : null}

                        {viewCompanyFee ? (
                          <th scope="col" className="px-4 py-3">
                            Company Fee
                          </th>
                        ) : null}

                        {viewAdvisorNetFeePercentage ? (
                          <th scope="col" className="px-4 py-3">
                            Advisor Net Fee %
                          </th>
                        ) : null}

                        {viewAdvisorNetFeeAmount ? (
                          <th scope="col" className="px-4 py-3">
                            Advisor Net Fee
                          </th>
                        ) : null}

                        <th scope="col" className="px-4 py-3"></th>
                        <th scope="col" className="px-4 py-3"></th>
                      </tr>
                    </thead>
                    {fetchedData?.submitted?.length > 0 &&
                    ongoingInitial === "Ongoing" ? (
                      <tbody>
                        {fetchedData?.submitted.map((item) => (
                          <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                            {viewRecordID ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.recordID}
                              </td>
                            ) : null}

                            {viewStartDate ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {/* {item.startDate} */}

                                {dateFormat(item.startDate, "mmmm dS, yyyy")}
                              </td>
                            ) : null}

                            {viewCompany ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.companyName}
                              </td>
                            ) : null}

                            {viewClientName ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.clientSurname}, {item.clientForename}
                              </td>
                            ) : null}

                            {viewClientRef ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.clientRef}
                              </td>
                            ) : null}

                            {viewAdvisor ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.advisorSurname}, {item.advisorFirstName}
                              </td>
                            ) : null}

                            {viewProduct ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.product}
                              </td>
                            ) : null}

                            {viewProvider ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.productProvider}
                              </td>
                            ) : null}

                            {viewOwner ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.ownerSurname}, {item.ownerFirstName}
                              </td>
                            ) : null}

                            {viewOwner ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.financeReference}
                              </td>
                            ) : null}

                            {viewInvestedValue ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {getCurrencySymbol(item.currency) +
                                  item.investedValue}
                              </td>
                            ) : null}

                            {viewAdvisorFee ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.advisorDeclaredFee}%
                              </td>
                            ) : null}

                            {viewTotalFee ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {getCurrencySymbol(item.currency) +
                                  item.totalClientFeePaidFromProduct}
                              </td>
                            ) : null}

                            {viewCompanyPercentage ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.companyNetFeePercentage}%
                              </td>
                            ) : null}

                            {viewCompanyFee ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {getCurrencySymbol(item.currency) +
                                  item.companyNetFeeAmount}
                              </td>
                            ) : null}

                            {viewAdvisorNetFeePercentage ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {item.advisorNetFeePayablePercentage}%
                              </td>
                            ) : null}

                            {viewAdvisorNetFeeAmount ? (
                              <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                {getCurrencySymbol(item.currency) +
                                  item.advisorNetFeePayableAmount}
                              </td>
                            ) : null}

                            <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <a
                                onClick={() => {
                                  setSubmittedID(item.submittedID);
                                  setInvestedValue(item.investedValue);
                                  setAdvisorFee(item.advisorDeclaredFee);
                                  setTotalFee(
                                    item.totalClientFeePaidFromProduct
                                  );
                                  setCompanyPercentage(
                                    item.companyNetFeePercentage
                                  );
                                  setCompanyFee(item.companyNetFeeAmount);
                                  setAdvisorNetFeePercentage(
                                    item.advisorNetFeePayablePercentage
                                  );
                                  setAdvisorNetFee(
                                    item.advisorNetFeePayableAmount
                                  );

                                  setOpenModal(true);
                                }}
                                className="text-orange-600 hover:text-red-900"
                              >
                                <Button color="warning" size="sm">
                                  Edit
                                </Button>
                              </a>
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={() => areYouSure(item.submittedID)}
                                className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr className="border-b dark:border-blue-100 bg-blue-50">
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                            Converted to:
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                            <select
                              id="currency"
                              name="currency"
                              onChange={(e) => setConversion(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option value={0}></option>
                              <option value={1}>£</option>
                              <option value={2}>$</option>
                              <option value={3}>€</option>
                              <option value={4}>CZK</option>
                            </select>
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap dark:text-white">
                            {conversion > 0
                              ? getCurrencySymbol(conversion) +
                                " " +
                                numberWithCommas(sumCompanyFeeTotal())
                              : null}

                            {/* {
                              // getCurrencySymbol(currency) +
                              numberWithCommas(sumInvestedTotal())
                            } */}
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap dark:text-white">
                            {conversion > 0
                              ? getCurrencySymbol(conversion) +
                                " " +
                                numberWithCommas(sumAdvisorFeeTotal())
                              : null}

                            {/* {
                              // getCurrencySymbol(currency) +
                              numberWithCommas(sumFeeTotal())
                            } */}
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap dark:text-white">
                            {/* {
                              // getCurrencySymbol(currency) +
                              numberWithCommas(sumCompanyFeeTotal())
                            } */}
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white"></td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap dark:text-white">
                            {/* {
                              // getCurrencySymbol(currency) +
                              numberWithCommas(sumAdvisorFeeTotal())
                            } */}
                          </td>

                          <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td
                            colSpan="16"
                            className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-red-500"
                          >
                            No results found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </section>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="space-y-3">
                <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                  Edit Submitted Business Record
                </h2>

                {/* <div className="mt-20 text-red-600">
                  How much of the record should we allow to be updated?
                </div> */}

                <div className="flex flex-row">
                  <div className="w-1/4 p-1">
                    <label
                      for="investedValue"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Invested Value
                    </label>
                    <input
                      type="text"
                      id="investedValue"
                      name="investedValue"
                      onChange={(e) => {
                        setInvestedValue(e.target.value);
                      }}
                      value={investedValue ? investedValue : ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="w-1/4 p-1">
                    <label
                      for="advisorFee"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Advisor Fee
                    </label>
                    <input
                      type="text"
                      id="advisorFee"
                      name="advisorFee"
                      onChange={(e) => {
                        setAdvisorFee(e.target.value);
                      }}
                      value={advisorFee ? advisorFee : ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="w-1/4 p-1">
                    <label
                      for="totalFee"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Total Fee
                    </label>
                    <input
                      type="text"
                      id="totalFee"
                      name="totalFee"
                      onChange={(e) => {
                        setTotalFee(e.target.value);
                      }}
                      value={totalFee ? totalFee : ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="w-1/4 p-1">
                    <label
                      for="companyPercentage"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company %
                    </label>
                    <input
                      type="text"
                      id="companyPercentage"
                      name="companyPercentage"
                      onChange={(e) => {
                        setCompanyPercentage(e.target.value);
                      }}
                      value={companyPercentage ? companyPercentage : ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/4 p-1">
                    <label
                      for="companyFee"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company Fee
                    </label>
                    <input
                      type="text"
                      id="companyFee"
                      name="companyFee"
                      onChange={(e) => {
                        setCompanyFee(e.target.value);
                      }}
                      value={companyFee ? companyFee : ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="w-1/4 p-1">
                    <label
                      for="advisorNetFeePercentage"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Advisor Net Fee %
                    </label>
                    <input
                      type="text"
                      id="advisorNetFeePercentage"
                      name="advisorNetFeePercentage"
                      onChange={(e) => {
                        setAdvisorNetFeePercentage(e.target.value);
                      }}
                      value={
                        advisorNetFeePercentage ? advisorNetFeePercentage : ""
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="w-1/4 p-1">
                    <label
                      for="advisorNetFee"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Advisor Net Fee
                    </label>
                    <input
                      type="text"
                      id="advisorNetFee"
                      name="advisorNetFee"
                      onChange={(e) => {
                        setAdvisorNetFee(e.target.value);
                      }}
                      value={advisorNetFee ? advisorNetFee : ""}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>

                <button
                  type="button"
                  onClick={async () => {
                    update();
                  }}
                  className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
                >
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          {/* <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              ></button>
                            </div>
                          </div> */}
                          <div className="text-gray-700 text-md w-full text-center">
                            Select the columns you want to display
                          </div>
                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="divide-gray-200">
                              <tr>
                                <td
                                  for="viewRecordID"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  RecordID
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewRecordID"
                                    checked={viewRecordID}
                                    value={1}
                                    onChange={() =>
                                      setViewRecordID(!viewRecordID)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewStartDate"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Start Date
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewStartDate"
                                    checked={viewStartDate}
                                    value={1}
                                    onChange={() =>
                                      setViewStartDate(!viewStartDate)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewCompany"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Company
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewCompany"
                                    checked={viewCompany}
                                    value={1}
                                    onChange={() =>
                                      setViewCompany(!viewCompany)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewClientName"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Client Name
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewClientName"
                                    checked={viewClientName}
                                    value={1}
                                    onChange={() =>
                                      setViewClientName(!viewClientName)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewClientRef"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Client Ref
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewClientRef"
                                    checked={viewClientRef}
                                    value={1}
                                    onChange={() =>
                                      setViewClientRef(!viewClientRef)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewAdvisor"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Advisor
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewAdvisor"
                                    checked={viewAdvisor}
                                    value={1}
                                    onChange={() =>
                                      setViewAdvisor(!viewAdvisor)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewProduct"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Product
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewProduct"
                                    checked={viewProduct}
                                    value={1}
                                    onChange={() =>
                                      setViewProduct(!viewProduct)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewProvider"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Provider
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewProvider"
                                    checked={viewProvider}
                                    value={1}
                                    onChange={() =>
                                      setViewProvider(!viewProvider)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewOwner"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Owner
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewOwner"
                                    checked={viewOwner}
                                    value={1}
                                    onChange={() => setViewOwner(!viewOwner)}
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewInvestedValue"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Invested Value
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewInvestedValue"
                                    checked={viewInvestedValue}
                                    value={1}
                                    onChange={() =>
                                      setViewInvestedValue(!viewInvestedValue)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewAdvisorFee"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Advisor Fee
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewAdvisorFee"
                                    checked={viewAdvisorFee}
                                    value={1}
                                    onChange={() =>
                                      setViewAdvisorFee(!viewAdvisorFee)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewTotalFee"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Total Fee
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewTotalFee"
                                    checked={viewTotalFee}
                                    value={1}
                                    onChange={() =>
                                      setViewTotalFee(!viewTotalFee)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewCompanyPercentage"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Company Percentage
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewCompanyPercentage"
                                    checked={viewCompanyPercentage}
                                    value={1}
                                    onChange={() =>
                                      setViewCompanyPercentage(
                                        !viewCompanyPercentage
                                      )
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewCompanyFee"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Company Fee
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewCompanyFee"
                                    checked={viewCompanyFee}
                                    value={1}
                                    onChange={() =>
                                      setViewCompanyFee(!viewCompanyFee)
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewAdvisorNetFeePercentage"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Advisor Net Fee Percentage
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewAdvisorNetFeePercentage"
                                    checked={viewAdvisorNetFeePercentage}
                                    value={1}
                                    onChange={() =>
                                      setViewAdvisorNetFeePercentage(
                                        !viewAdvisorNetFeePercentage
                                      )
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  for="viewAdvisorNetFeeAmount"
                                  class="block mt-1 text-sm font-medium text-gray-700 dark:text-white"
                                >
                                  Advisor Net Fee Amount
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="viewAdvisorNetFeeAmount"
                                    checked={viewAdvisorNetFeeAmount}
                                    value={1}
                                    onChange={() =>
                                      setViewAdvisorNetFeeAmount(
                                        !viewAdvisorNetFeeAmount
                                      )
                                    }
                                    className="align-center h-4"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                          <div className="mt-6">
                            <a
                              href="#"
                              onClick={async () => {
                                await updateView();
                                setOpen(false);
                              }}
                              className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
                            >
                              Update
                            </a>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}
